import React from "react";
import "./Environment.css";

const Environment = () => {
  return (
    <section className="environment">
      <h1>EXPLORE OUR ENVIRONMENT</h1>
      <div className="env">
      <iframe
      src="https://www.google.com/maps/embed?pb=!4v1723094617721!6m8!1m7!1s4x8IWNPqGSKFUZFiH2zJUA!2m2!1d26.17903516294486!2d78.21506772993803!3f98.311396450642!4f-0.5103305836784102!5f0.7820865974627469"
      title="Unique title"
      width="80%"
      height="450"
      style={{ border: 0 }}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
      </div>
    </section>
  );
};

export default Environment;

